// AppMessageTypes.js
// Event Types sent from App to Service Worker / Pusher Controller
'use strict';

export const UPDATE_SENTRY_CONFIG = 'UPDATE_SENTRY_CONFIG';
export const ENABLE_DEBUG = 'ENABLE_DEBUG';
export const DISABLE_DEBUG = 'DISABLE_DEBUG';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const PING_SERVICE_WORKER = 'PING_SERVICE_WORKER';
export const INIT_PUSHER = 'INIT_PUSHER';
export const DISCONNECT_PUSHER = 'DISCONNECT_PUSHER';
export const SET_AUTH_HEADERS = 'SET_AUTH_HEADERS';
export const SET_AUTHORIZER_LANGUAGE = 'SET_AUTHORIZER_LANGUAGE';
export const FREE_ZONE_CHECK_IN = 'FREE_ZONE_CHECK_IN';
export const GET_AB_TEST_TOKEN = 'GET_AB_TEST_TOKEN';
export const USER_ONLINE_STATUS = 'USER_ONLINE_STATUS';
export const USER_REPLY_PRICE = 'USER_REPLY_PRICE';

export const GET_CHANNEL_STATUS = 'GET_CHANNEL_STATUS';
export const GET_STREAM_CHANNEL_STATUS = 'GET_STREAM_CHANNEL_STATUS';
export const GET_PUSHER_CONNECTION_STATUS = 'GET_PUSHER_CONNECTION_STATUS';

export const SUBSCRIBE_PRESENCE_MESSAGE_CHANNEL =
  'SUBSCRIBE_PRESENCE_MESSAGE_CHANNEL';
export const UNSUBSCRIBE_PRESENCE_MESSAGE_CHANNEL =
  'UNSUBSCRIBE_PRESENCE_MESSAGE_CHANNEL';
export const SUBSCRIBE_STREAM_CHANNEL = 'SUBSCRIBE_STREAM_CHANNEL';
export const UNSUBSCRIBE_STREAM_CHANNEL = 'UNSUBSCRIBE_STREAM_CHANNEL';
export const SUBSCRIBE_STREAM_VIEWER_CHANNEL =
  'SUBSCRIBE_STREAM_VIEWER_CHANNEL';
export const UNSUBSCRIBE_STREAM_VIEWER_CHANNEL =
  'UNSUBSCRIBE_STREAM_VIEWER_CHANNEL';
export const SUBSCRIBE_GOAL_CHANNEL = 'SUBSCRIBE_GOAL_CHANNEL';
export const UNSUBSCRIBE_GOAL_CHANNEL = 'UNSUBSCRIBE_GOAL_CHANNEL';
export const SUBSCRIBE_PRESENCE_GOAL_CHANNEL =
  'SUBSCRIBE_PRESENCE_GOAL_CHANNEL';
export const UNSUBSCRIBE_PRESENCE_GOAL_CHANNEL =
  'UNSUBSCRIBE_PRESENCE_GOAL_CHANNEL';
export const SUBSCRIBE_NOTIFICATION_CHANNEL = 'SUBSCRIBE_NOTIFICATION_CHANNEL';
export const UNSUBSCRIBE_NOTIFICATION_CHANNEL =
  'UNSUBSCRIBE_NOTIFICATION_CHANNEL';
export const SUBSCRIBE_CAMPAIGN_CHANNEL = 'SUBSCRIBE_CAMPAIGN_CHANNEL';
export const UNSUBSCRIBE_CAMPAIGN_CHANNEL = 'UNSUBSCRIBE_CAMPAIGN_CHANNEL';
export const SUBSCRIBE_FEED_CHANNEL = 'SUBSCRIBE_FEED_CHANNEL';
export const UNSUBSCRIBE_FEED_CHANNEL = 'UNSUBSCRIBE_FEED_CHANNEL';
export const SUBSCRIBE_PRESENCE_ASSET_CHANNEL =
  'SUBSCRIBE_PRESENCE_ASSET_CHANNEL';
export const UNSUBSCRIBE_PRESENCE_ASSET_CHANNEL =
  'UNSUBSCRIBE_PRESENCE_ASSET_CHANNEL';
export const SUBSCRIBE_PRESENCE_ORDER_CHANNEL =
  'SUBSCRIBE_PRESENCE_ORDER_CHANNEL';
export const SUBSCRIBE_PRESENCE_CLIENT_CHANNEL =
  'SUBSCRIBE_PRESENCE_CLIENT_CHANNEL';
export const SUBSCRIBE_PRESENCE_USER_CHANNEL =
  'SUBSCRIBE_PRESENCE_USER_CHANNEL';

export const SEND_PLAYER_STATUS_TO_CHANNEL = 'SEND_PLAYER_STATUS_TO_CHANNEL';

export const RESTORE_REDUX = 'RESTORE_REDUX';
export const RESET_FCM_TOKEN = 'RESET_FCM_TOKEN';

export const UPDATE_PUSHER_DISTINCT_ID = 'UPDATE_PUSHER_DISTINCT_ID';
