// Sentry.js
const isOnServiceWorker = typeof self !== 'undefined' && self.importScripts;
const tagName = process.env.TAG_NAME;
const branchName = process.env.BRANCH_NAME;
const shortSha = process.env.SHORT_SHA;

let Sentry = null;
let _sessionId = null;
let Sentry2 = null; // the second sentry for user bug report

if (isOnServiceWorker) {
  Sentry = self._SENTRY_INSTANCE_;
}

export const setSessionId = ({ sessionId }) => {
  _sessionId = sessionId;
  if (Sentry) {
    Sentry.setTag('session_id', _sessionId);
  }

  if (Sentry2) {
    Sentry2.setTag('session_id', _sessionId);
  }
};

export const initSentry = async ({ sentryConfig = {} } = {}) => {
  if (!Sentry) {
    if (isOnServiceWorker) {
      Sentry = self._SENTRY_INSTANCE_;
    } else {
      const LazySentry = await import('@sentry/browser');
      Sentry = LazySentry;
    }
  }

  const defaultSentryConfig = {
    dsn: 'https://fb3a44a2f78e4676911d7f323294dcb9@sentry.io/1273292',
    environment: 'default',
    release: `${tagName || branchName?.replace(/[^a-z\d]/gi, '-')}-${shortSha}`,
    level: 'error',
    sampleRate: 0,
    tracesSampleRate: 0,
    // Ref. https://docs.sentry.io/clients/javascript/tips/
    ignoreErrors: [
      // Device memory full.
      'QuotaExceededError',
      // Safari 12.2 bug. https://bugs.webkit.org/show_bug.cgi?id=197050
      'Connection to Indexed Database server lost.',
      // User maybe is in incognito mode.
      'No available storage method found.',
      // Autoplay video.
      'The request is not allowed by the user agent',
      "play() failed because the user didn't interact with the document first",
      // Player unmonted.
      'AbortError: The operation was aborted',

      // sentry error more than 1k
      'VIDEOJS CODE 4: No compatible source was found for this media.',
      'Invalid target for t#trigger; must be a DOM node or evented object.',
      "Cannot read properties of null (reading 'error')",
      'AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22',
      'VIDEOJS CODE 5: Unsupported keySystem or supportedConfigurations.',
      'No compatible source was found for this media.',
      "Cannot read properties of null (reading 'getChild')",
      'VIDEOJS CODE 4: The media could not be loaded, either because the server or network failed or because the format is not supported.',
      'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.',
      'Non-Error promise rejection captured with keys: error',
      "Failed to execute 'createMediaKeys' on 'MediaKeySystemAccess': MediaDrmBridge creation failed",
      "null is not an object (evaluating 'this.player_.getChild')",
      "UnknownError: Attempt to iterate a cursor that doesn't exist",
      'AbortError: The transaction was aborted, so the request cannot be fulfilled.',
      'Key system configuration is not supported',

      // pubsub fetch error
      'TypeError: Load failed',
      'TypeError: Failed to fetch',

      // load GTM script error
      'Object captured as promise rejection with keys: error',

      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',

      // Some error from android webview.
      'vivo_checkMediaPausedReason is not defined',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  };

  const {
    init,
    BrowserClient,
    defaultStackParser,
    defaultIntegrations,
    makeFetchTransport,
    Hub,
  } = Sentry;

  // main sentry
  const sentryOptions = {
    integrations: defaultIntegrations,
    ...defaultSentryConfig,
    ...sentryConfig,
  };
  if (!isOnServiceWorker) {
    const { BrowserTracing, Replay } = Sentry;
    sentryOptions.integrations.push(new BrowserTracing());
    sentryOptions.integrations.push(new Replay());
  }
  init(sentryOptions);

  // second sentry
  // refer to https://github.com/getsentry/sentry-javascript/blob/7.0.0/MIGRATION.md#explicit-client-options
  const sentry2Options = {
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: defaultIntegrations,
    ...defaultSentryConfig,
    ...sentryConfig,
    dsn: 'https://6215b20088bd4e34b025b17e96a65119@o37462.ingest.sentry.io/4504162520137728',
    sampleRate: 1,
    beforeSend(event) {
      if (event.tags.bug_report) return event;
      return null;
    },
  };
  const client = new BrowserClient(sentry2Options);
  const hub = new Hub(client);
  Sentry2 = hub;

  if (_sessionId) {
    Sentry?.setTag('session_id', _sessionId);
    Sentry2?.setTag('session_id', _sessionId);
  }
  return Sentry || {};
};

export const updateSentryConfig = async ({ sentryConfig }) => {
  const sentry = await getSentry();
  sentry.setLevel?.(sentryConfig.level);
  sentry.setTag?.('environment', sentryConfig.environment);
};

export const getSentry = async () => {
  if (isOnServiceWorker) {
    return Sentry || self._SENTRY_INSTANCE_;
  }
  return Sentry || initSentry();
};

/**
 * refer to: https://github.com/getsentry/sentry-javascript/blob/7.0.0/MIGRATION.md#severity-severitylevel-and-severitylevels
 * The new version of sentry deprecated the Severity enum
 * So extract it from old version of sentry
 */
export const Severity = {
  /** JSDoc */
  Fatal: 'fatal',
  /** JSDoc */
  Error: 'error',
  /** JSDoc */
  Warning: 'warning',
  /** JSDoc */
  Log: 'log',
  /** JSDoc */
  Info: 'info',
  /** JSDoc */
  Debug: 'debug',
};

/**
 * return the second sentry for bug report
 * @returns { import('@sentry/browser').Hub }
 */
export const getSentry2 = () => {
  return Sentry2;
};
